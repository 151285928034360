<template>
	<div class="container mt-4">
		<h2 class="label-header">Flagged Question</h2>
		<!-- <div class="card mb-4">
			<div class="card-body">
				<div class="d-flex justify-content-center flex-column align-items-center" v-if="isLoading" style="width: 100%; heigth: 100px;">
					<span style="width: 30px; height: 30px;" class="spinner-border spinner-border-sm mr-3" role="status" aria-hidden="true"></span>Loading Data
				</div>
				<form class="row g-3"  @submit.prevent="onSubmit" novalidate v-if="!isLoading">
					<div class="col-6">
						<label class="form-label">Course name</label>
						<select class="form-select" v-model="vv.course_id.$model" @change="loadTopic">
							<option :value="initialValue">Select Course</option>
							<option v-for="(item, i) in courseList" :key="i" :value="item.course_id">{{ item.course_code }}</option>
						</select>
						<span class="error">{{ vv?.course_id?.$errors[0]?.$message }}</span>
					</div>
					<div class="col-6">
						<label class="form-label">Topic</label>
						<select class="form-select" v-model="vv.topic_id.$model">
							<option value="0">All Topics</option>
							<option v-for="(item, i) in topicList" :key="i" :value="item.topic_id">{{ item.topic_title }}</option>
						</select>
						<span class="error">{{ vv?.topic_id?.$errors[0]?.$message }}</span>
					</div>
					<div class="col-12">
						<button class="btn btn-primary">Fecth Lecture Note</button>
					</div>
				</form>
			</div>
		</div> -->
		<div class="card mb-4">
			<div class="card-body">
				<div class="table-responsive">
					<table class="table table-hover table-striped table-bordered">
						<thead>
							<tr>
								<th>Question</th>
								<th>Course</th>
								<th>Topic</th>
								<th>Year</th>
								<th>Option A</th>
								<th>Option B</th>
								<th>Option C</th>
								<th>Option D</th>
								<th>Option E</th>
							</tr>
						</thead>
						<tbody v-if="questionList.length > 0">
							<tr v-for="(item, i) in questionList" :key="i">
								<td>
									<div v-html="$generalHelper.decodeHtmlEntites(item.question)"></div>
								</td>
								<td>{{ item.course }}</td>
								<td>{{ item.topic }}</td>
								<td>{{ item.year }}</td>
								<td>
									<div v-html="$generalHelper.decodeHtmlEntites(item.option_a)"></div>
								</td>
								<td>
									<div v-html="$generalHelper.decodeHtmlEntites(item.option_b)"></div>
								</td>
								<td>
									<div v-html="$generalHelper.decodeHtmlEntites(item.option_c)"></div>
								</td>
								<td>
									<div v-html="$generalHelper.decodeHtmlEntites(item.option_d)"></div>
								</td>
								<td>
									<div v-html="$generalHelper.decodeHtmlEntites(item.option_e)"></div>
								</td>
							</tr>
						</tbody>
						<tbody v-if="questionList.length === 0">
							<tr>
								<td colspan="6">
									<div class="d-flex justify-content-center flex-column align-items-center">
										No Flagged Question yet
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="d-flex justify-content-center flex-column align-items-center" v-if="lastIndex !== 0">
					<button class="btn btn-sm btn-secondary" @click="loadMore()">Load More</button>
				</div>
			</div>
			<div class="d-flex justify-content-center flex-column align-items-center card-loader" v-if="isLoading1">
				<span style="width: 50px; height: 50px;" class="spinner-border spinner-border-sm mr-3" role="status" aria-hidden="true"></span>Processing Data
			</div>
		</div>
	</div>
</template>

<script>
import { reactive, computed } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { audioService } from '@/services/audio.services'
export default {
	name: 'FlagQuestion',
	setup () {
		const fform = reactive({
			topic_id: null,
			course_id: null
		})
		const rules = computed(() => (
		{
			topic_id: { required },
			course_id: { required }
		}
		))
		const vv = useVuelidate(rules, fform);
		return {
			vv,
			fform
		}
	},
	data() {
		return {
			isLoading: true,
			isLoading1: false,
			initialValue: null,
			courseList: [],
			topicList: [],
			questionList: [],
			lastIndex: 0
		};
	},
	mounted(){
		this.onSubmit()
	},
	methods: {
		loadCourse () {
			audioService.loadCourse()
			.then((response) => {
				this.isLoading = false
				if(parseInt(response.data.message_code) === 200){
					this.courseList = response.data.courseList
				}
			})
			.catch((e) => {
				this.isLoading = false
				console.log(e)
			})
		},
		loadTopic () {
			this.isLoading = true
			audioService.loadTopic(this.fform.course_id)
			.then((response) => {
				this.isLoading = false
				if(parseInt(response.data.message_code) === 200){
					this.topicList = response.data.topicList
				}
			})
			.catch((e) => {
				this.isLoading = false
				console.log(e)
			})
		},
		onSubmit () {
			//this.vv.$touch();
			//if (this.vv.$invalid) return;
			this.isLoading1 = true
			//audioService.fetchQuestions(this.fform.topic_id, this.lastIndex)
			audioService.fetchQuestions(this.lastIndex)
				.then((response) => {
					this.isLoading1 = false
					if(parseInt(response.data.message_code) === 200){
						this.questionList = response.data.questionList
						setTimeout(() => {
							eval('MathJax.Hub.Queue(["Typeset", MathJax.Hub])');
						}, 1000)
					}
					this.lastIndex = response.data.lastIndex
				})
				.catch((e) => {
					this.isLoading1 = false
					console.log(e)
				})
		},
		loadMore () {
			audioService.fetchQuestions(this.lastIndex)
				.then((response) => {
					this.isLoading1 = false
					if(parseInt(response.data.message_code) === 200){
						for(const item of response.data.questionList){
							this.questionList.push(item)
						}
						setTimeout(() => {
							eval('MathJax.Hub.Queue(["Typeset", MathJax.Hub])');
						}, 1000)
					}
					this.lastIndex = response.data.lastIndex
				})
				.catch((e) => {
					this.isLoading1 = false
					console.log(e)
				})
		}
	}
};
</script>

<style scoped>
label{
	font-weight: 550;
	font-size: 14px;
	margin-bottom: 5px;
}
.card-loader{
	position: absolute;
	width: 100%;
	height: 100%;
	background: rgba(225, 225, 225, 0.85);
}
</style>
